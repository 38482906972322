html {
  font-family: sans-serif;
  font-size: 1.125em;
}

header {
  border-bottom: 1px solid black;
}

main {
  max-width: 90ch;
  margin: 0 auto;
}

#local-time {
  display: block;
  font-size: 8em;
  text-align: center;
  margin-top: 0.25em;
}

a {
  color: rgb(241, 38, 225);
}
